import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import api from '../api';

const Footer = (settings) => {
  const [metaData, setMetaData] = useState({});
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const footerData = [
    {
      title: "Terms and Conditions",
      links: [
        { label: "Terms and Conditions", path: "/terms-conditions" },
        { label: "Privacy Policy", path: "/privacy-policy" },
        { label: "Return Policy", path: "/return-policy" },
        { label: "Shipping Policy", path: "/shipping-policy" },
        { label: "Warranty Policy", path: "/warranty-policy" },
        // { label: "Sitemap", path: "./sitemap.xml" },
      ],
    },
    {
      title: "Quick Links",
      links: [
        { label: "About Us", path: "/about-us" },
        { label: "Why Us", path: "/why-us" },
        { label: "Our Projects", path: "/projects" },
        { label: "FAQ", path: "/faqs" },
        { label: "Career", path: "/career" },
        { label: "Blogs", path: "/blogs" },
        { label: "Contact Us", path: "/contact-us" },
      ],
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post('/subscriber-add', { email });
      if (response.data && response.data.result) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message || "An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    if (settings.setting && settings.setting.length > 0) {
      const meta = {};
      settings.setting.forEach((setting) => {
        if (setting.type === "footer_logo_path") {
          meta.footer_logo_path = setting.value;
        }
        if (setting.type === "payment_method_images") {
          meta.payment_method_images = setting.value;
        }
        if (setting.type === "website_name") {
          meta.website_name = setting.value;
        }
        if (setting.type === "frontend_copyright_text") {
          meta.frontend_copyright_text = setting.value;
        }
        if (setting.type === "about_us_description") {
          meta.about_us_description = setting.value;
        }
        if (setting.type === "facebook_link") {
          meta.facebook_link = setting.value;
        }
        if (setting.type === "twitter_link") {
          meta.twitter_link = setting.value;
        }
        if (setting.type === "instagram_link") {
          meta.instagram_link = setting.value;
        }
        if (setting.type === "linkedin_link") {
          meta.linkedin_link = setting.value;
        }
      });
      setMetaData(meta);
    }
  }, [settings]);

  return (
    <footer className="main-footer">
      <div className="widgets-section">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center text-center">
            {/* Newsletter */}
            <div className="footer-column col-lg-12 col-md-12 col-sm-12 col-12 mb-1">
              <div className="footer-widget">
                <div className="newsletter">
                  <p className="widget-title fw-600 text-uppercase">Subscribe to our newsletter</p>
                  <p className="widget-sub-title fw-600">Transform Your Space with Us—Subscribe for Weekly Ideas!</p>
                </div>
              </div>
            </div>
            <div className="footer-column col-lg-12 col-md-12 col-sm-12 col-12 mb-5">
              <div className="footer-widget">
                <div className="newsletter">
                  <form className='d-flex justify-content-center align-items-center' onSubmit={handleSubmit}>
                    <input className='text-center'
                      type="email"
                      name="EMAIL"
                      placeholder="ENTER EMAIL ADDRESS" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      className="theme-btn btn-style-one"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">

            {/* About Us */}
            <div className="footer-column col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget about-widget text-center">
                <Link to="#">
                  <img className='footer-logo pb-3' src={metaData.footer_logo_path} alt={metaData.website_name} title="" />
                </Link>
                {metaData.about_us_description && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: metaData.about_us_description }}
                  ></div>
                )}

                <ul className="social-icon-two justify-content-center pt-2">
                  <li>
                    <Link to={metaData.twitter_link}>
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.instagram_link}>
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.facebook_link}>
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.linkedin_link}>
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>


            {/* We Accept */}
            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget about-widget text-center">
                <p className='widget-heading'>We accept:</p>
                <Link to="#">
                  <img src={metaData.payment_method_images} alt={metaData.website_name} title="" />
                </Link>
              </div>
            </div>

            {footerData.map((section, index) => (
              <div
                key={index}
                className={`footer-column d-none d-sm-flex col-lg-${index === 0 ? 2 : 1} col-md-6 col-sm-12`}
              >
                <div className="footer-widget">
                  <ul className="user-links">
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex}>
                          <Link to={link.path}>{link.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}

            <div className="faq-column mt-4 d-block d-sm-none">
              <div className="inner-column mb-md-50">
                <ul className="accordion-box style-two bg-transparent p-0">
                  {footerData.map((section, index) => (
                    <li
                      className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                      key={index}
                    >
                      <div
                        className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5>{section.title}</h5>
                        <div className="icon fa fa-plus"></div>
                      </div>
                      <div
                        className={`acc-content ${activeIndex === index ? 'current' : ''}`}
                        ref={(el) => (contentRefs.current[index] = el)}
                      >
                        <div className="content">
                          <ul className="user-links">
                            {section.links.map((link, linkIndex) => (
                              <li key={linkIndex}>
                                {link.label === "Sitemap" ? (
                                  <a href={link.path} target="_blank" rel="noopener noreferrer">
                                    {link.label}
                                  </a>
                                ) : (
                                  <Link to={link.path}>{link.label}</Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Contact */}
            <div className="footer-column col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget contact-widget">
                <h5 className="widget-title">Get in Touch</h5>
                <div className="widget-content">
                  <ul className="text">
                    <li>For Customer Queries:</li>
                    <li><a href="mailto:care@niyaliving.in">Care@niyaliving.in</a></li>
                    <li>For Projects/Bulk Orders:</li>
                    <li><a href="mailto:projects@niyaliving.in">Projects@niyaliving.in</a></li>
                    <li>For PR/Marketing:</li>
                    <li><a href="mailto:info@niyaliving.in">info@niyaliving.in</a></li>
                  </ul>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div dangerouslySetInnerHTML={{ __html: metaData.frontend_copyright_text }} />
    </footer>
  );
};

export default Footer;
