import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from '../../api';
import { Helmet } from "react-helmet";

const BlogDetails = () => {
    const { slug } = useParams();
    const [article, setArticle] = useState(null);
    const [latestPosts, setLatestPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const articleResponse = await api.get(`/blog/${slug}`);
                setArticle(articleResponse.data.data.blog);

                const latestPostsResponse = await api.get('/blogs');
                setLatestPosts(latestPostsResponse.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!article) {
        return <div>No article found</div>;
    }

    return (
        <>
            <Helmet>
                <title>{article.meta_title}</title>
                <meta name="title" content={article.meta_title} />
                <meta name="description" content={article.meta_description} />
            </Helmet>

            <section
                className="page-title"
                style={{ backgroundImage: "url(/images/background/page-title.jpg)" }}
            >
                <div className="container-fluid">
                    <div className="title-outer text-center">
                        <h1 className="title">{article.title}</h1>
                        <ul className="page-breadcrumb">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li><Link to="/blogs">Blog</Link></li>
                            <li>{article.title}</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="blog-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="blog-details__left">
                                <div className="blog-details__img">
                                    <img src={article.banner} alt={article.title} />
                                    <div className="blog-details__date">
                                        <span className="day">
                                            {new Date(article.created_at).getDate()}
                                        </span>
                                        <span className="month">
                                            {new Date(article.created_at).toLocaleString('default', { month: 'short' })}
                                        </span>
                                    </div>
                                </div>
                                <div className="blog-details__content">
                                    <h3 className="blog-details__title text-justify">{article.title}</h3>
                                    <p className="blog-details__text-2 text-justify" dangerouslySetInnerHTML={{ __html: article.description }}></p>
                                </div>
                                <div className="blog-details__bottom">
                                    <p className="blog-details__tags">
                                        <span>Tags</span> <Link to="#">Business</Link>{" "}
                                        <Link to="#">Agency</Link>
                                    </p>
                                    <div className="blog-details__social-list">
                                        <Link to="#">
                                            <i className="fab fa-twitter"></i>
                                        </Link>
                                        <Link to="#">
                                            <i className="fab fa-facebook"></i>
                                        </Link>
                                        <Link to="#">
                                            <i className="fab fa-pinterest-p"></i>
                                        </Link>
                                        <Link to="#">
                                            <i className="fab fa-instagram"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="sidebar">
                                <div className="sidebar__single sidebar__post">
                                    <h3 className="sidebar__title">Latest Posts</h3>
                                    <ul className="sidebar__post-list list-unstyled">
                                        {latestPosts.map((post) => (
                                            <li key={post.id}>
                                                <div className="sidebar__post-image">
                                                    <img src={post.banner} alt={post.title} />
                                                </div>
                                                <div className="sidebar__post-content">
                                                    <h3>
                                                        <Link to={`/blog/${post.slug}`}>
                                                            {post.title}
                                                        </Link>
                                                    </h3>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="sidebar__single sidebar__tags">
                                    <h3 className="sidebar__title">Tags</h3>
                                    <div className="sidebar__tags-list">
                                        <Link to="#">Consulting</Link> <Link to="#">Agency</Link>{" "}
                                        <Link to="#">Business</Link> <Link to="#">Digital</Link>{" "}
                                        <Link to="#">Experience</Link> <Link to="#">Technology</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetails;
