import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../api';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";

const Career = (settings) => {
    const [metaData, setMetaData] = useState({});
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        profile: "",
        name: "",
        surname: "",
        email: "",
        phone: "",
        message: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedFormData = { ...formData };

            console.log("Form Data to Submit:", updatedFormData);

            const response = await api.post("/career/save", updatedFormData);

            const { result, message } = response.data;

            if (result) {
                toast.success(message);
                setFormData({
                    profile: "",
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    message: ""
                });
            } else {
                toast.error(message || "An error occurred");
            }
        } catch (err) {
            const errorMessage = err.response?.data?.message || "An error occurred";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (settings.setting && settings.setting.length > 0) {
            const meta = {};
            settings.setting.forEach((setting) => {
                if (setting.type === "facebook_link") {
                    meta.facebook_link = setting.value;
                }
                if (setting.type === "twitter_link") {
                    meta.twitter_link = setting.value;
                }
                if (setting.type === "instagram_link") {
                    meta.instagram_link = setting.value;
                }
                if (setting.type === "linkedin_link") {
                    meta.linkedin_link = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    return (
        <>
            <Helmet>
                <title>Career Opportunities at Niya Living | Join Our Team</title>
                <meta name="title" content="Career Opportunities at Niya Living | Join Our Team" />
                <meta name="description" content="Explore career opportunities at Niya Living. Learn about open positions, job benefits, and how you can contribute to our growing team of passionate professionals." />
            </Helmet>

            <section className="about-page-title" style={{ backgroundImage: `url('/images/about-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">CAREER</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="career">
                <div className="container-fluid">
                    <h1 className='title'>Join Our Team</h1>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="heading">
                                Build the Future of Design with Us
                            </div>
                            <div className="text">
                                At <b>NIYA LIVING</b>, we’re not just creating furniture; we’re crafting stories that last a lifetime. Our team is driven by a passion for design, innovation, and sustainability, all while putting people at the heart of everything we do. If you're ready to be part of a dynamic, forward-thinking company shaping the future of contemporary living, we’d love to meet you.
                            </div>
                            <div className="heading">
                                What We Stand For
                            </div>
                            <div className="text">
                                <b>Our Core Values</b>
                                <ul>
                                    <li><b>Innovation:</b> We embrace creativity to design timeless, functional, and modern furniture that inspires.</li>
                                    <li><b>Sustainability:</b> Our commitment to eco-friendly materials and processes is part of everything we do.</li>
                                    <li><b>Team Spirit:</b> Collaboration and inclusion fuel our workplace culture, where every idea matters.</li>
                                    <li><b>Customer-First Approach:</b> Our customers’ happiness is our ultimate benchmark for success.</li>
                                </ul>
                            </div>
                            <div className="heading">
                                Why Work With Us?
                            </div>
                            <div className="text">
                                <b>A Culture That Empowers</b><br />
                                We believe that every voice adds to the harmony of our success. Whether you’re a seasoned professional or just starting, we invest in your growth, provide opportunities to make an impact, and celebrate milestones together.
                                <ul>
                                    <li><b>Growth Opportunities:</b> Unlock your potential with ongoing mentorship and learning programs.</li>
                                    <li><b>Inspiring Workspaces:</b> Collaborate in spaces designed to fuel creativity.</li>
                                    <li><b>Benefits That Matter:</b> Enjoy a competitive package, flexible hours, and perks to help you thrive.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <div className="heading">
                                Open Positions:
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 career-form">
                            <form className="form pt-4" action="" onSubmit={handleSubmit} method="post">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Designer" />
                                            <label className="form-check-label" for="inlineRadio1">Designer</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Sales Associate" />
                                            <label className="form-check-label" for="inlineRadio1">Sales Associate</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Dealer" />
                                            <label className="form-check-label" for="inlineRadio1">Dealer</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Influencer" />
                                            <label className="form-check-label" for="inlineRadio1">Influencer</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Job" />
                                            <label className="form-check-label" for="inlineRadio1">Job </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="profile" onChange={handleChange} id="inlineRadio1" value="Vendor" />
                                            <label className="form-check-label" for="inlineRadio1">⁠Vendor</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="name" className="form-control" type="text" onChange={handleChange} value={formData.name} placeholder="Enter Name *" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="surname" className="form-control" type="text" onChange={handleChange} value={formData.surname} placeholder="Enter Surname" />
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="phone" className="form-control" type="text" onChange={handleChange} value={formData.phone} placeholder="Enter Phone" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <input name="email" className="form-control" type="email" onChange={handleChange} value={formData.email} placeholder="Enter Email *" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <textarea name="message" className="form-control" rows="7" onChange={handleChange} value={formData.message} placeholder="Enter Message"></textarea>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="theme-btn btn-style-one" data-loading-text="Please wait..."><span className="btn-title">Send message</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <section className="follow-social-media">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h2 className="heading">
                                Follow Us on Social Media
                            </h2>
                            <p className="text">
                                Stay inspired and updated with our latest designs and offers:
                            </p>
                            <ul className="social-icon-two justify-content-center pt-2">
                                <li>
                                    <Link to={metaData.twitter_link}>
                                        <i className="fab fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.instagram_link}>
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.facebook_link}>
                                        <i className="fab fa-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.linkedin_link}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Career;
