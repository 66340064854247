import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from '../../api';
import { Helmet } from "react-helmet";

const ProjectDetails = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await api.get(`/project/${slug}`);
        setProject(projectResponse.data.data.project);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!project) {
    return <div>No project found</div>;
  }

  const formattedDate = new Date(project.created_at).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <Helmet>
        <title>{project.meta_title}</title>
        <meta name="title" content={project.meta_title} />
        <meta name="description" content={project.meta_description} />
      </Helmet>

      {/* Page Title Section */}
      <section
        className="page-title"
        style={{ backgroundImage: 'url(/images/background/27.jpg)' }}
      >
        <div className="container-fluid">
          <div className="title-outer text-center">
            <h1 className="title">{project.title || "Project Title"}</h1>
            <ul className="page-breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li><Link to="/projects">Project</Link></li>
              <li>{project.title || "Project Title"}</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Project Details Section */}
      <section className="project-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="project-details__top">
                <div className="project-details__img">

                  <img
                    src={project.banner || "/images/products-page-title.png"}
                    alt={project.title || "Project Image"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="project-details__content-right">
                <div className="project-details__details-box">
                  <div className="row">
                    <div className="col">
                      <p className="project-details__client">Date</p>
                      <h4 className="project-details__name">{formattedDate || "N/A"}</h4>
                    </div>
                    <div className="col">
                      <p className="project-details__client">Client</p>
                      <h4 className="project-details__name">{project.client || "Unknown"}</h4>
                    </div>
                    <div className="col">
                      <p className="project-details__client">Website</p>
                      <h4 className="project-details__name">
                        <a href={project.website} target="_blank" rel="noopener noreferrer">
                          {project.website || "N/A"}
                        </a>
                      </h4>
                    </div>
                    <div className="col">
                      <p className="project-details__client">Location</p>
                      <h4 className="project-details__name">{project.location || "N/A"}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Project Content */}
          <div className="project-details__content">
            <div className="row">
              <div className="col-xl-12">
                <div className="project-details__content-left">
                  <h3 className="mb-4 mt-5">{project.title || "Project Title"}</h3>

                  <p className="text-justify"
                    dangerouslySetInnerHTML={{ __html: project.description || "<p>No description available</p>" }}
                  />

                  <ul className="list-style-two mb-0">
                    {project.features?.map((feature, index) => (
                      <li key={index}>
                        <i className="fa fa-check-circle"></i> {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProjectDetails;
