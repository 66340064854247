import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import Sidenav from "./modules/Sidenav";
import AddressModal from "./modules/AddressModal";
import AddressEdit from "./modules/AddressEdit";
import { Link } from "react-router-dom";
import api from "../../api";
import 'react-toastify/dist/ReactToastify.css';

const Profile = () => {
    const [loginData, setLoginData] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [AddressDatas, setAddressData] = useState([]);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const dropdownRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        new_password: "",
        confirm_password: ""
    });
    const [dropdownId, setDropdownId] = useState(null);

    const handleDeleteAddress = async (id) => {
        try {
            const token = sessionStorage.getItem('access_token');
            console.log("Deleting address with ID:", id);
            const response = await api.get('addresses/destroy', {
                params: { address_id: id },
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.result) {
                toast.success('Address deleted successfully');
                setAddressData((prevAddresses) =>
                    prevAddresses.filter((address) => address.id !== id)
                );
            } else {
                toast.error('Failed to delete address');
            }
        } catch (err) {
            console.error("Error deleting address:", err);
            toast.error('Error deleting address');
        }
    };

    const handleMakeDefault = async (id) => {
        try {
            const token = sessionStorage.getItem('access_token');
            console.log("Setting default for address ID:", id);
            const response = await api.get('addresses/set-default', {
                params: { address_id: id },
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.result) {
                toast.success('Address set as default!');
                setAddressData((prevAddresses) =>
                    prevAddresses.map((address) =>
                        address.id === id
                            ? { ...address, set_default: 1 }
                            : { ...address, set_default: 0 }
                    )
                );
            } else {
                toast.error('Failed to set address as default');
            }
        } catch (err) {
            console.error("Error setting default address:", err);
            toast.error('Error setting default address');
        }
    };

    const handleEditClick = (id) => {
        console.log("Editing address with ID:", id);
        setSelectedAddressId(id);
        setIsEditModalOpen(true);
    };

    const openAddModal = () => {
        setIsAddModalOpen(true);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const handleAddressSubmit = (isNewAddress) => {
        if (isNewAddress) {
            toast.success('Address added successfully');
            closeAddModal();
        } else {
            toast.success('Address edited successfully');
            closeEditModal();
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const token = sessionStorage.getItem('access_token');
            try {
                const response = await api.post('/get-user-by-access_token', {
                    access_token: token,
                });
                if (response) {
                    setLoginData(response.data);
                    setFormData({
                        name: response.data.name || "",
                        email: response.data.email || "",
                        phone: response.data.phone || "",
                    });
                }
            } catch (err) {
                console.error("Error fetching user data:", err);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchAddressData = async () => {
            setLoading(true);
            try {
                const token = sessionStorage.getItem('access_token');
                const response = await api.get('/user/shipping/address', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.success) {
                    setAddressData(response.data.data);
                }
            } catch (err) {
                console.error("Error fetching address data:", err);
                toast.error('Error fetching address data');
            } finally {
                setLoading(false);
            }
        };

        if (loginData) {
            fetchAddressData();
        }
    }, [loginData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = sessionStorage.getItem('access_token');
        try {
            const response = await api.post('auth/password/forget_request', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.result) {
                toast.success("Profile updated successfully");
            } else {
                toast.error("Error updating profile");
            }
        } catch (err) {
            console.error("Error updating profile:", err);
            toast.error("Error updating profile");
        }
    };

    const toggleDropdown = (id) => {
        console.log("Toggling dropdown for ID:", id);
        setDropdownId((prevId) => (prevId === id ? null : id));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                console.log("Clicked outside, closing dropdown");
                setDropdownId(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <section className="dashboard">
            <div className="container-fluid">
                <Sidenav />

                <div className="aiz-user-panel">
                    <div className="aiz-titlebar mb-4">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1 className="fs-20 fw-700 text-dark">Manage Profile</h1>
                            </div>
                        </div>
                    </div>

                    <div className="card rounded-0 shadow-none border mt-4">
                        <div className="card-header pt-4 border-bottom-0 bg-transparent">
                            <h5 className="mb-0 fs-18 fw-700 text-dark">Basic Info</h5>
                        </div>
                        <div className="card-body">
                            <form
                                action="/user/update-profile"
                                method="POST"
                                encType="multipart/form-data"
                                onSubmit={handleSubmit}
                            >
                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label fs-14">
                                        Your name
                                    </label>
                                    <div className="col-md-10">
                                        <input
                                            type="text"
                                            className="form-control rounded-0"
                                            placeholder="Your name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label fs-14">
                                        Your Phone
                                    </label>
                                    <div className="col-md-10">
                                        <input
                                            type="text"
                                            className="form-control rounded-0"
                                            placeholder="Your Phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label fs-14">
                                        Photo
                                    </label>
                                    <div className="col-md-10">
                                        <input
                                            type="file"
                                            className="form-control rounded-0"
                                            name="photo"
                                        />
                                        <div className="file-preview sm">
                                            <div
                                                className="d-flex justify-content-between align-items-center mt-3 file-preview-item"
                                            >
                                                <div className="align-items-center d-flex justify-content-center thumb">
                                                    {loginData && loginData.avatar_original ? (
                                                        <img
                                                            src={loginData.avatar_original}
                                                            className="img-fit h-100"
                                                            alt="Avatar"
                                                        />
                                                    ) : (
                                                        <img
                                                            src="/images/user.jpg"
                                                            className="img-fit h-100"
                                                            alt="Avatar"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label fs-14">
                                        Your Password
                                    </label>
                                    <div className="col-md-10">
                                        <input
                                            type="password"
                                            className="form-control rounded-0"
                                            placeholder="New Password"
                                            name="new_password"
                                            // value={formData.new_password}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-md-2 col-form-label fs-14">
                                        Confirm Password
                                    </label>
                                    <div className="col-md-10">
                                        <input
                                            type="password"
                                            className="form-control rounded-0"
                                            placeholder="Confirm Password"
                                            name="confirm_password"
                                            // value={formData.confirm_password}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-0 text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary rounded-0 w-150px mt-3"
                                    >
                                        Update Profile
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* Address Section */}
                    <div className="card rounded-0 shadow-none border mt-4 address-section">
                        <div className="card-header pt-4 border-bottom-0 bg-transparent">
                            <h5 className="mb-0 fs-18 fw-700 text-dark">Address</h5>
                        </div>
                        <div className="card-body">
                            {/* Existing Address */}
                            {AddressDatas && AddressDatas.length > 0 ? (
                                AddressDatas.map((AddressData) => (
                                    <div className="border p-4 mb-4 position-relative" key={AddressData.id}>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">Address:</span>
                                            <span className="col-md-8 text-dark">{AddressData.address}</span>
                                        </div>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">Postal code:</span>
                                            <span className="col-md-10 text-dark">{AddressData.postal_code}</span>
                                        </div>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">City:</span>
                                            <span className="col-md-10 text-dark">{AddressData.city_name}</span>
                                        </div>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">State:</span>
                                            <span className="col-md-10 text-dark">{AddressData.state_name}</span>
                                        </div>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">Country:</span>
                                            <span className="col-md-10 text-dark">{AddressData.country_name}</span>
                                        </div>
                                        <div className="row fs-14 mb-2 mb-md-0">
                                            <span className="col-md-2 text-secondary">Phone:</span>
                                            <span className="col-md-10 text-dark">{AddressData.phone}</span>
                                        </div>
                                        {AddressData.set_default === 1 && (
                                            <div className="address-status pt-2 pt-md-4 pe-md-5">
                                                <span className="badge text-white p-3 fs-12">Default</span>
                                            </div>
                                        )}

                                        <div className="dropdown me-1 pt-2 pt-md-4 pe-md-3" ref={dropdownRef}>
                                            <button
                                                className="btn bg-secondary text-white px-1 py-1"
                                                type="button"
                                                onClick={() => toggleDropdown(AddressData.id)}
                                            >
                                                <i className="fa fa-ellipsis-v"></i>
                                            </button>
                                            {dropdownId === AddressData.id && (
                                                <div className="dropdown-menu dropdown-menu-right show">
                                                    <Link to="#"
                                                        className="dropdown-item"
                                                        onClick={() => handleEditClick(AddressData.id)}
                                                    >
                                                        Edit
                                                    </Link>
                                                    <Link
                                                        to="#"
                                                        className="dropdown-item"
                                                        onClick={() => handleMakeDefault(AddressData.id)}
                                                    >
                                                        Make This Default
                                                    </Link>
                                                    <Link
                                                        to="#"
                                                        className="dropdown-item"
                                                        onClick={() => handleDeleteAddress(AddressData.id)}
                                                    >
                                                        Delete
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="border p-4 mb-4 position-relative">
                                    <li className="fs-16 fw-500 text-dark pb-1">No default address available</li>
                                </div>)}

                            {/* Add New Address */}
                            <div
                                className="c-pointer text-center bg-light has-transition hov-bg-soft-light"
                                onClick={openAddModal}
                            >
                                <div className="border text-dark p-3 mb-3">
                                    <i className="fa fa-plus"></i>
                                    <div className="alpha-7 fs-14 fw-700">Add New Address</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Change Email */}
                    <form action="/new-user-email" method="POST">
                        <div className="card rounded-0 shadow-none border mt-4">
                            <div className="card-header pt-4 border-bottom-0 bg-transparent">
                                <h5 className="mb-0 fs-18 fw-700 text-dark">
                                    Change your email
                                </h5>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label className="fs-14">Your Email</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="input-group mb-3">
                                            <input
                                                type="email"
                                                className="form-control rounded-0"
                                                placeholder="Your Email"
                                                name="email"
                                                value={loginData ? loginData.email : "Email"}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 input-group-append">
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary new-email-verification"
                                        >
                                            <span className="d-none loading">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                Sending Email...
                                            </span>
                                            <span className="default">Verify</span>
                                        </button>
                                    </div>
                                    <div className="form-group mb-0 text-right">
                                        <button
                                            type="submit"
                                            className="btn btn-primary rounded-0 w-150px mt-3"
                                        >
                                            Update Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Add New Address Modal */}
            <AddressModal isOpen={isAddModalOpen} onClose={closeAddModal} onSubmit={() => handleAddressSubmit(true)} />

            {/* Edit Address Modal */}
            <AddressEdit isOpen={isEditModalOpen} onClose={closeEditModal} addressId={selectedAddressId} onSubmit={() => handleAddressSubmit(false)} />
        </section>
    );
};

export default Profile;
