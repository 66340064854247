import React, { useState, useRef, useEffect } from "react";
import AddressModal from "../pages/Dashboard/modules/AddressModal";
import { Link } from "react-router-dom";
import api from "../api";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  const [quantities, setQuantities] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const [products, setProducts] = useState([]);
  const [AddressDatas, setAddressData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownId, setDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [StateData, setStateData] = useState([]);
  const [CityData, setCityData] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    country_id: "",
    state_id: "",
    city_id: "",
    postal_code: "",
    phone: "",
  });

  const getGuestId = () => {
    const name = "guest_id=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = sessionStorage.getItem("access_token");

    try {
      const temp_user_id = await getGuestId();

      const updatedFormData = { ...formData };
      if (temp_user_id) {
        updatedFormData.temp_user_id = temp_user_id;
      }

      const response = await api.post(
        "guest-user-account-create",
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { result, message, access_token, user } = response.data;

      if (result) {
        toast.success(message);
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("user_data", JSON.stringify(user));
        setLoginData(user);
        document.cookie =
          "temp_user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      } else {
        toast.error(message || "An error occurred");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || "An error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const userData = sessionStorage.getItem("user_data");
    if (userData) {
      try {
        setLoginData(JSON.parse(userData));
      } catch {
        setLoginData(null);
      }
    }
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/countries");
        if (data.success) {
          setCountryData(data.data);
        } else {
          toast.error("Failed to fetch countries.");
        }
      } catch {
        toast.error("An error occurred while fetching countries.");
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.country_id) {
      const fetchStates = async () => {
        setLoading(true);
        try {
          const { data } = await api.get(
            `/states-by-country/${formData.country_id}`
          );
          if (data.success) {
            setStateData(data.data);
          } else {
            toast.error("Failed to fetch states.");
          }
        } catch {
          toast.error("An error occurred while fetching states.");
        } finally {
          setLoading(false);
        }
      };

      fetchStates();
    }
  }, [formData.country_id]);

  useEffect(() => {
    if (formData.state_id) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const { data } = await api.get(
            `/cities-by-state/${formData.state_id}`
          );
          if (data) {
            setCityData(data);
          } else {
            toast.error("Failed to fetch cities.");
          }
        } catch {
          toast.error("An error occurred while fetching cities.");
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    }
  }, [formData.state_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditClick = (id) => {
    setSelectedAddressId(id);
    setIsDropdownOpen(false);
    openModal();
  };

  const handleDeleteAddress = async (id) => {
    try {
      const token = sessionStorage.getItem("access_token");

      const response = await api.get("addresses/destroy", {
        params: { address_id: id },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.result) {
        toast.success("Address deleted successfully");
        setAddressData((prevAddresses) =>
          prevAddresses.filter((address) => address.id !== id)
        );
      } else {
        toast.error("Failed to delete address");
      }
    } catch (err) {
      toast.error("Error deleting address");
    }
  };

  const handleMakeDefault = async (id) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const response = await api.get("addresses/set-default", {
        params: { address_id: id },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.result) {
        toast.success("Address set as default!");
        setAddressData((prevAddresses) =>
          prevAddresses.map((address) =>
            address.id === id
              ? { ...address, set_default: 1 }
              : { ...address, set_default: 0 }
          )
        );
      } else {
        toast.error("Failed to set address as default");
      }
    } catch (err) {
      toast.error("Error setting default address");
    }
  };

  const initiatePayment = async () => {
    try {
      if (!AddressDatas || AddressDatas.length === 0) {
        toast.error("Please add an address before placing your order.");
        return;
      }

      const defaultAddress = AddressDatas.find(
        (address) => address.set_default === 1
      );
      if (!defaultAddress) {
        toast.error("Please set a default address before placing your order.");
        return;
      }

      const payload = {
        owner_id: 1,
        user_id: loginData?.id,
        payment_type: "phonepe",
      };

      const response = await api.post("order-drm", payload);

      if (response) {
        const combinedOrderId = response.data.combined_order_id;

        const paymentPayload = { combined_order_id: combinedOrderId };

        const paymentResponse = await api.post(
          "initiate-phonepe-payment",
          paymentPayload
        );

        if (paymentResponse.data.success) {
          window.location.href = paymentResponse.data.redirectUrl;
        } else {
          toast.error(
            "Payment initiation failed: " + paymentResponse.data.message
          );
        }
      } else {
        toast.error("Order creation failed: " + response.data.message);
      }
    } catch (error) {
      console.error("Payment initiation error:", error);
      toast.error("Something went wrong during payment initiation.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (loginData && loginData.id) {
        const token = sessionStorage.getItem("access_token");
        try {
          const response = await api.post(
            "/carts",
            { user_id: loginData.id },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          const cartData = response.data.data[0];
          if (
            cartData &&
            cartData.cart_items &&
            cartData.cart_items.length > 0
          ) {
            setProducts(cartData.cart_items);
            setQuantities(
              cartData.cart_items.map((item) => item.quantity || 1)
            );
          } else {
            setProducts([]);
            setQuantities([]);
          }
        } catch (err) {
          setError(err.message || "Failed to load cart data");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [loginData]);

  const calculateSubtotal = () => {
    return products
      .reduce((acc, product) => {
        const price = parseFloat(product.product_price || 0);
        const quantity = parseInt(product.quantity || 1, 10);
        return acc + price * quantity;
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    const fetchAddressData = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem("access_token");
        const response = await api.get("/user/shipping/address", {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.success) {
          setAddressData(response.data.data);
        }
      } catch (err) {
        toast.error("Error fetching address data");
      } finally {
        setLoading(false);
      }
    };

    if (loginData) {
      fetchAddressData();
    }
  }, [loginData]);

  const toggleDropdown = (id) => {
    setDropdownId((prevId) => (prevId === id ? null : id));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModalAndDropdown = () => {
    setIsDropdownOpen(false);
    setIsModalOpen(false);
    setDropdownId(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userData = sessionStorage.getItem("user_data");
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setLoginData(parsedData);
      } catch {
        setLoginData(null);
      }
    }
  }, []);

  const fetchSummary = async () => {
    let userId = null;
    const token = sessionStorage.getItem("access_token");
    if (loginData && loginData.id) {
      userId = loginData.id;
    } else {
      userId = getGuestId();
    }
    if (userId) {
      const formData = new FormData();
      formData.append(
        loginData && loginData.id ? "user_id" : "temp_user_id",
        userId
      );
      const queryString = new URLSearchParams(formData).toString();
      try {
        const response = await api.post(`/cart-summary?${queryString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const summary = response.data;
        setSummary(summary);
      } catch (err) {
        setError(err.message || "Failed to load cart data");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSummary();
  });

  const applyCoupon = async (e) => {
    e.preventDefault();

    if (!couponCode) {
      return toast.info("Please enter a coupon code.");
    }

    const token = sessionStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("user_id", loginData.id);
    formData.append("owner_id", 1);
    formData.append("coupon_code", couponCode);

    try {
      const response = await api.post("/coupon-apply", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = response.data;
      if (result.result) {
        toast.success(result.message || "Coupon applied successfully.");
        await fetchSummary();
      } else {
        toast.error(result.message || "Coupon application failed.");
      }
    } catch (err) {
      setError(err.message || "Failed to apply coupon.");
    }
  };

  const removeCoupon = async () => {
    setCouponCode("");
    const token = sessionStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("user_id", loginData.id);
    formData.append("owner_id", 1);
    // formData.append("coupon_code", couponCode);

    try {
      const response = await api.post("/coupon-remove", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = response.data;
      if (result.result) {
        toast.success(result.message || "Coupon removed successfully.");
        await fetchSummary();
      } else {
        toast.error(result.message || "Coupon removed failed.");
      }
    } catch (err) {
      setError(err.message || "Failed to apply coupon.");
    }
  };
  return (
    <section className="checkout">
      <div className="container my-4 pt-70 bg-white">
        <div className="section-content">
          <div className="row mt-30">
            {loginData !== null ? (
              <div className="col-md-12">
                <h3 className="mb-30">Billing Address</h3>
                <div className="billing-details">
                  {AddressDatas && AddressDatas.length > 0 ? (
                    AddressDatas.map((AddressData) => (
                      <div
                        className="border p-4 mb-4 position-relative"
                        key={AddressData.id}
                      >
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">
                            Address:
                          </span>
                          <span className="col-md-8 text-dark">
                            {AddressData.address}
                          </span>
                        </div>
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">
                            Postal code:
                          </span>
                          <span className="col-md-10 text-dark">
                            {AddressData.postal_code}
                          </span>
                        </div>
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">City:</span>
                          <span className="col-md-10 text-dark">
                            {AddressData.city_name}
                          </span>
                        </div>
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">
                            State:
                          </span>
                          <span className="col-md-10 text-dark">
                            {AddressData.state_name}
                          </span>
                        </div>
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">
                            Country:
                          </span>
                          <span className="col-md-10 text-dark">
                            {AddressData.country_name}
                          </span>
                        </div>
                        <div className="row fs-14 mb-2 mb-md-0">
                          <span className="col-md-2 text-secondary">
                            Phone:
                          </span>
                          <span className="col-md-10 text-dark">
                            {AddressData.phone}
                          </span>
                        </div>

                        {AddressData.set_default === 1 && (
                          <div className="address-status pt-2 pt-md-4 pe-md-5">
                            <span className="badge text-white p-3 fs-12">
                              Default
                            </span>
                          </div>
                        )}

                        <div
                          className="dropdown me-1 pt-2 pt-md-4 pe-md-3"
                          ref={dropdownRef}
                        >
                          <button
                            className="btn bg-secondary text-white px-1 py-1"
                            type="button"
                            onClick={() => toggleDropdown(AddressData.id)}
                          >
                            <i className="fa fa-ellipsis-v"></i>
                          </button>
                          {dropdownId === AddressData.id && (
                            <div className="dropdown-menu dropdown-menu-right show">
                              <Link
                                to="#"
                                className="dropdown-item"
                                onClick={() =>
                                  handleMakeDefault(AddressData.id)
                                }
                              >
                                Make This Default
                              </Link>
                              <Link
                                to="#"
                                className="dropdown-item"
                                onClick={() =>
                                  handleDeleteAddress(AddressData.id)
                                }
                              >
                                Delete
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <li className="fs-14 fw-400 text-dark pb-1">
                      No default address available
                    </li>
                  )}

                  <div
                    className="c-pointer text-center bg-light has-transition hov-bg-soft-light"
                    onClick={openModal}
                  >
                    <div className="border text-dark p-3 mb-3">
                      <i className="fa fa-plus"></i>
                      <div className="alpha-7 fs-14 fw-700">
                        Add New Address
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <form className="form-default mb-4" onSubmit={handleSubmit}>
                <h3>User Info</h3>
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Name</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        className="form-control mb-3 rounded-0"
                        placeholder="Your name"
                        rows="2"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Email</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="email"
                        className="form-control mb-3 rounded-0"
                        placeholder="Your Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Address</label>
                    </div>
                    <div className="col-md-10">
                      <textarea
                        className="form-control mb-3 rounded-0"
                        placeholder="Your Address"
                        rows="2"
                        name="address"
                        required
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Country</label>
                    </div>
                    <div className="col-md-10">
                      <div className="mb-3">
                        <select
                          className="form-control aiz-selectpicker rounded-0"
                          data-live-search="true"
                          name="country_id"
                          required
                          value={formData.country_id}
                          onChange={handleInputChange}
                        >
                          <option value="">Select your country</option>
                          {CountryData.map((ctr) => (
                            <option key={ctr.id} value={ctr.id}>
                              {ctr.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>State</label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control mb-3 aiz-selectpicker rounded-0"
                        name="state_id"
                        required
                        value={formData.state_id}
                        onChange={handleInputChange}
                      >
                        <option value="">Select your state</option>
                        {StateData.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>City</label>
                    </div>
                    <div className="col-md-10">
                      <select
                        className="form-control mb-3 aiz-selectpicker rounded-0"
                        name="city_id"
                        required
                        value={formData.city_id}
                        onChange={handleInputChange}
                      >
                        <option value="">Select your city</option>
                        {CityData.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Postal code</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control mb-3 rounded-0"
                        placeholder="Your Postal Code"
                        name="postal_code"
                        required
                        value={formData.postal_code}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 mt-md-2">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-10">
                      <input
                        type="text"
                        className="form-control mb-3 rounded-0"
                        placeholder="Format: 1234567890"
                        pattern="[6789][0-9]{9}"
                        name="phone"
                        required
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>


                <div className="col-md-12">
                  <div className="bg-soft-info p-2">
                    If you have already used the same mail address or phone
                    number before, please
                    <Link
                      to="/login"
                      className="fw-700 animate-underline-primary m-2"
                    >
                      Login
                    </Link>
                    first to continue
                  </div>
                  <button
                    type="submit"
                    className="theme-btn btn-style-one"
                  >
                    Continue to Delivery Info
                  </button>
                </div>
              </form>
            )}

            <div className="col-md-12 my-4">
              <h3>Coupon Code</h3>
              <form className="row coupon-form" onSubmit={applyCoupon}>
                <div className="col-md-4 position-relative">
                  <input
                    type="text"
                    name="coupon_code"
                    className="input-text form-control mr-1"
                    id="coupon_code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    placeholder="Enter Coupon Code"
                  />

                  {couponCode && (
                    <button
                      type="button"
                      className="btn btn-link text-danger position-absolute"
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                      onClick={removeCoupon}
                    >
                      &times;
                    </button>
                  )}
                </div>
                <div className="col-md-4">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one"
                    name="apply_coupon"
                    value="Apply Coupon"
                  >
                    <span className="btn-title">Apply Coupon</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-12 mt-4">
              <h3>Order Summary</h3>
              <table className="table table-bordered cart-total mt-4">
                <tbody>
                  <tr>
                    <td>Cart Subtotal</td>
                    <td>{summary.sub_total}</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td>{summary.tax}</td>
                  </tr>
                  {summary.coupon_applied && (
                    <tr>
                      <td>Coupon Applied</td>
                      <td>{summary.discount}</td>
                    </tr>
                  )}

                  <tr>
                    <td>Shipping and Handling</td>
                    <td>{summary.shipping_cost}</td>
                  </tr>
                  <tr>
                    <td>Order Total</td>
                    <td>{summary.grand_total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-12 mt-60">
              <div className="payment-method">
                <div className="mt-3">
                  <button type="button" className="theme-btn btn-style-one">
                    <span className="btn-title" onClick={initiatePayment}>
                      Place Order
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddressModal isOpen={isModalOpen} onClose={closeModalAndDropdown} />
    </section>
  );
};

export default CheckoutForm;
