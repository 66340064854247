import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUs = (settings) => {
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        if (settings.setting && settings.setting.length > 0) {
            const meta = {};
            settings.setting.forEach((setting) => {
                if (setting.type === "facebook_link") {
                    meta.facebook_link = setting.value;
                }
                if (setting.type === "twitter_link") {
                    meta.twitter_link = setting.value;
                }
                if (setting.type === "instagram_link") {
                    meta.instagram_link = setting.value;
                }
                if (setting.type === "linkedin_link") {
                    meta.linkedin_link = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    return (
        <>
            <Helmet>
                <title>About Us | Niya Living</title>
                <meta name="title" content="About Us | Niya Living" />
                <meta name="description" content="Learn about Niya Living, where we offer stylish and durable furniture and home decor. Our mission is to provide high-quality products that enhance your living spaces." />
            </Helmet>
            <section className="about-page-title" style={{ backgroundImage: `url('/images/about-us.png')` }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">ABOUT US</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-us">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text">
                                At <b>NIYA LIVING</b>, design is a soulful journey : where creativity, craftsmanship, and innovation seamlessly come together. We believe that design is more than just form and function. It’s about connecting with the essence of materials, techniques, and nature in a way that reflects individuality and evokes emotion. Our passion lies in expressing this creativity through minimalist, contemporary designs that are always fresh and forward-thinking.
                            </div>
                            <div className="heading">
                                Our Philosophy
                            </div>
                            <div className="text">
                                We are driven by a philosophy that blends culture, craftsmanship, and industry, where the richness of tradition meets the clarity of modernity. our design process is a perfect balance of precision and passion, celebrating uniqueness while embracing new perspectives. By interpreting global design trends with a focus on aesthetics that are both timeless and contemporary, we create furniture that stands the test of time. Sustainability is at the heart of everything we do. We use responsibly sourced materials like wood from well-managed forests, metals, stone, and natural fibers, ensuring our creations are not only beautiful but built to endure. Each piece is crafted with care, prioritizing durability, beauty, and eco-conscious practices.
                            </div>
                            <div className="heading">
                                Our Journey
                            </div>
                            <div className="text">
                                With over 30 years of expertise, we have earned a reputation for delivering high-quality, contemporary furniture and decor. Specializing in solid wood, veneer, metals, and marble, our collections reflect a keen eye for detail and a commitment to excellence. For three decades, we have proudly served the global market, with our products reaching clients across the USA, Europe, the UK, Singapore, South Africa, and Australia.
                            </div>
                            <div className="heading">
                                Niya Living in India
                            </div>
                            <div className="text">
                                Now, we are excited to bring our premium, globally inspired designs to India. As we introduce locally crafted furniture that combines traditional artistry with modern aesthetics, our mission is to contribute to the growth of India’s vibrant handicraft industry. With a team of skilled artisans, designers, and quality experts, we aim to redefine luxury living in India, while staying true to our roots of craftsmanship, innovation, and sustainability.                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-values">
                <h1 className="title">Welcome to Niya Living—a space where design meets soul.</h1>
                <div className="values-bg" style={{ backgroundImage: `url('/images/about-service.png')` }}>
                    <div className="values-container">
                        <h2 className="heading">Our Values</h2>
                        <div className="values-contant">
                            <div className="values-box">
                                <img src="/images/values/value-1.png" alt="values-1" />
                                <p className="text">Clean Energy</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-2.png" alt="values-2" />
                                <p className="text">Frequent Health Checkups For Employees</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-3.png" alt="values-3" />
                                <p className="text">Environment Friendly Chemicals</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-4.png" alt="values-4" />
                                <p className="text">Water Harvesting</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-5.png" alt="values-5" />
                                <p className="text">Fair Trade</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-6.png" alt="values-6" />
                                <p className="text">Smaller Carbon Footprint</p>
                            </div>
                            <div className="values-box">
                                <img src="/images/values/value-7.png" alt="values-7" />
                                <p className="text">Sustainably Sourced</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="about-feature">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="image">
                                <img src='/images/about-image-3.png' alt='about 1' />
                            </div>
                            <div className="content-box">
                                <h2 className="heading">Respect for People</h2>
                                <p className="text">At Niya Living, we believe in fostering an environment where everyone—our team, artisans, and customers—can thrive and grow. We prioritize respect, honesty, and collaboration in all our relationships. Whether it’s our skilled craftsmen, our valued customers, or our employees, we are committed to building meaningful connections based on trust and shared values.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="image">
                                <img src='/images/abou-image-2.png' alt='about 1' />
                            </div>
                            <div className="content-box">
                                <h2 className="heading">Respect for the Environment</h2>
                                <p className="text">Sustainability is at the heart of what we do. We use eco-friendly materials and practices to minimize our impact on the planet and create furniture that stands the test of time. From sourcing responsibly to reducing waste, we are dedicated to preserving the environment for future generations.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="image">
                                <img src='/images/about-image-1.png' alt='about 1' />
                            </div>
                            <div className="content-box">
                                <h2 className="heading">Authenticity in Craftsmanship</h2>
                                <p className="text">Our furniture is crafted with precision, passion, and purpose. We work directly with skilled artisans to deliver timeless designs made from high-quality materials. By eliminating intermediaries, we ensure our products are not only authentic but also offered at a fair price, bringing genuine craftsmanship into your home.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="follow-social-media">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h2 className="heading">
                                Follow Us on Social Media
                            </h2>
                            <p className="text">
                                Stay inspired and updated with our latest designs and offers:
                            </p>
                            <ul className="social-icon-two justify-content-center pt-2">
                                <li>
                                    <Link to={metaData.twitter_link}>
                                        <i className="fab fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.instagram_link}>
                                        <i className="fab fa-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.facebook_link}>
                                        <i className="fab fa-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={metaData.linkedin_link}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
