import React from 'react';
import { Helmet } from "react-helmet";

const ReturnPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Return Policy | Niya Living</title>
        <meta name="title" content="Return Policy | Niya Living" />
        <meta name="description" content="Review Niya Living's return policy to learn about our easy return process, eligibility, and terms. Ensure a smooth return experience for your purchases." />
      </Helmet>

      <div className="auto-container py-5 text-justify">
        <h2>Return & Refund Policy</h2>

        <h4>Refund Policy</h4>

        <p>At Niya Living, we are committed to creating high-quality furniture that is built to last. Each piece undergoes rigorous quality checks at our factory outlet before it is dispatched to ensure it meets our high standards.</p>

        <p>In the unlikely event that you receive a product that is defective, damaged, or incorrectly delivered (including issues with style, size, color, quantity, or functionality), please contact us immediately at <a className="text-dark" href='mailto:care@niyaliving.in'>care@niyaliving.in</a> upon receipt. Please be aware that we cannot accept refund or replacement requests if more than 7 days have passed since delivery. We encourage you to inspect your items upon arrival.</p>

        <p>Upon notifying us of any issues, our quality control team will evaluate your claim. If it is deemed valid, we will either arrange for a repair or provide a replacement. We will handle the pick-up of the original item and send out a new one. Refunds, if applicable, will be credited to your account within 7 days after receiving the item. We apologize for any inconvenience and appreciate your patience as we work to resolve any issues.</p>

        <h4>Sale Items</h4>
        <p>Please note that only regular-priced items are eligible for refunds. Unfortunately, sale items cannot be returned or exchanged.</p>

      </div>
    </>
  );
};

export default ReturnPolicy;
