import React from "react";
import { Link } from "react-router-dom";

const Cart = ({
  products = [],
  quantities = [],
  handleQuantityChange,
  cartSummary = {},
  removeItemFromCart,
}) => {
  return (
    <section className="cart my-3">
      <div className="container bg-white">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive text-center">
                {products.length === 0 ? (
                  <section className="error-page">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="error-page__inner">
                            <div className="error-page__title-box">
                              <img
                                src="/images/empty-shopping-cart.avif"
                                alt="Empty shopping cart"
                              />
                              <h3 className="error-page__sub-title">
                                Your cart is currently empty!
                              </h3>
                            </div>
                            <Link
                              to="/products"
                              className="theme-btn btn-style-one shop-now"
                            >
                              <span className="btn-title">Back to Shopping</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <table className="table table-striped table-bordered tbl-shopping-cart">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Photo</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, index) => (
                        <tr className="cart_item" key={product.id}>
                          <td className="product-remove">
                            <button
                              title="Remove this item"
                              className="remove"
                              onClick={() => removeItemFromCart(product.id)}
                            >
                              ×
                            </button>
                          </td>
                          <td className="product-thumbnail">
                            <img
                              alt={product.product_name}
                              src={product.product_thumbnail_image}
                            />
                          </td>
                          <td className="product-name">
                            {product.product_name}
                            {product.size && (
                              <ul className="variation">
                                <li className="variation-size">
                                  Size: <span>{product.size}</span>
                                </li>
                              </ul>
                            )}
                          </td>
                          <td className="product-price">
                            <span className="amount">{product.product_price}</span>
                          </td>
                          <td className="product-quantity">
                            <div className="product-details__quantity">
                              <div className="quantity-box">
                                <button
                                  type="button"
                                  className="sub"
                                  onClick={() => handleQuantityChange(index, -1)}
                                >
                                  <i className="fa fa-minus"></i>
                                </button>
                                <input
                                  type="number"
                                  value={quantities[index] || 0}
                                  readOnly
                                />
                                <button
                                  type="button"
                                  className="add"
                                  onClick={() => handleQuantityChange(index, 1)}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                          <td className="product-subtotal">
                            <span className="amount">
                              {(product.product_price * quantities[index]).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {products.length > 0 && (
              <div className="col-md-12 mt-30">
                <div className="row">
                  <div className="col-md-8">
                    <h4>Cart Totals</h4>
                    <table className="table table-bordered cart-total">
                      <tbody>
                        <tr>
                          <td>Cart Subtotal</td>
                          <td>{cartSummary.sub_total || "0.00"}</td>
                        </tr>
                        {cartSummary.coupon_applied && (
                          <tr>
                            <td>Coupon Applied</td>
                            <td>-{cartSummary.discount || "0.00"}</td>
                          </tr>
                        )}
                        <tr>
                          <td>Shipping and Handling</td>
                          <td>{cartSummary.shipping_cost || "0.00"}</td>
                        </tr>
                        <tr>
                          <td>Order Total</td>
                          <td>{cartSummary.grand_total || "0.00"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Link className="theme-btn btn-style-one" to="/checkout">
                      <span className="btn-title">Proceed to Checkout</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;
