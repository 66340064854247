import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ password: "", confirmPassword: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);  
    const { password, confirmPassword } = formData;  
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }  
    setLoading(true);  
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");  
    if (!token) {
      setError("Invalid or missing token.");
      setLoading(false);
      return;
    }  
    try {
      const response = await api.post("/auth/password/reset", { token, password });
      if (response.data?.result) {
        toast.success(response.data.message || "Password reset successfully.");
        navigate("/login");
      } else {
        setError(response.data?.message || "Failed to reset password.");
      }
    } catch (err) {
      console.error(err.response ? err.response.data : err.message);
      setError(err.response?.data?.message || "Failed to reset password. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <section className="login-form">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 col-md-8 mx-auto py-lg-4">
            <div className="card shadow-none rounded-0 border-0">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src="images/collection.png"
                    alt="Customer Login Page"
                    className="img-fit h-100"
                  />
                </div>

                <div className="col-lg-6 p-4 p-lg-4 d-flex flex-column justify-content-center border bg-white">
                  <div className="logo mb-3 mx-auto mx-lg-0">
                    <img
                      src="images/favicon.png"
                      alt="Site Icon"
                      className="img-fit"
                    />
                  </div>

                  <div className="text-left">
                    <h2 className="heading">Reset Your Password</h2>
                    {/* <h5 className="sub-heading">Enter a new password to reset.</h5> */}
                  </div>

                  <div className="pt-3">
                    <form className="form-default" method="POST" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="newPassword" className="form-label">
                          New Password
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control rounded-0"
                            placeholder="New Password"
                            name="password"
                            id="newPassword"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          <i
                            className={`password-toggle icon far ${
                              showPassword ? "fa-eye-slash" : "fa-eye"
                            }`}
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <div className="position-relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control rounded-0"
                            placeholder="Confirm Password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                          />
                          <i
                            className={`password-toggle icon far ${
                              showPassword ? "fa-eye-slash" : "fa-eye"
                            }`}
                            onClick={() => setShowPassword(!showPassword)}
                          ></i>
                        </div>
                      </div>

                      <div className="mb-4 mt-2">
                        <button
                          type="submit"
                          className="theme-btn btn-style-one rounded-0"
                          disabled={loading}
                        >
                          {loading ? "Resetting Password..." : "Reset Password"}
                        </button>
                      </div>

                      {error && <p className="text-danger">{error}</p>}
                    </form>

                    <p className="fs-12 text-gray mb-0">
                      Don't have an account?
                      <a
                        href="/registration"
                        className="ms-2 fw-700 animate-underline-primary"
                      >
                        Register Now
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;