import React from 'react';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Niya Living</title>
        <meta name="title" content="Privacy Policy | Niya Living" />
        <meta name="description" content="Read Niya Living's privacy policy to understand how we collect, use, and protect your personal information. Learn about our commitment to your privacy and data security." />
      </Helmet>

      <div className="auto-container py-5 text-justify">
        <h2>Privacy Policy</h2>
        <p>At Niya Living, your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our website, you agree to the collection and use of information in accordance with this policy.</p>

        <h4>Information We Collect</h4>
        <ul>
          <li><strong>Personal Information:</strong> When you create an account, place an order, or contact us, we may collect personal information such as:</li>
          <ul>
            <li>Name</li>
            <li>Email Address</li>
            <li>Phone Number</li>
            <li>Shipping Address</li>
            <li>Billing Address</li>
            <li>Payment Information (processed securely through our payment processors)</li>
          </ul>
          <li><strong>Automatically Collected Information:</strong> We may collect information that your browser sends whenever you visit our website. This may include:</li>
          <ul>
            <li>Your computer's Internet Protocol (IP) address</li>
            <li>Browser type</li>
            <li>Browser version</li>
            <li>The pages of our website that you visit</li>
            <li>The time and date of your visit</li>
            <li>The time spent on those pages</li>
          </ul>
        </ul>

        <h4>How We Use Your Information</h4>
        <ul>
          <li><strong>Order Processing:</strong> To process and fulfill your orders, manage your account, and provide you with customer support.</li>
          <li><strong>Communication:</strong> To communicate with you about your orders, respond to inquiries, and send updates.</li>
          <li><strong>Marketing:</strong> To send you promotional materials, newsletters, and other information if you opt-in to receive such communications.</li>
          <li><strong>Analytics:</strong> To analyze website traffic and improve our services and website functionality.</li>
        </ul>

        <h4>Data Security</h4>
        <p>We take your data security seriously. We implement a variety of security measures to maintain the safety of your personal information, including:</p>
        <ul>
          <li><strong>Encryption:</strong> Using SSL (Secure Socket Layer) technology to encrypt sensitive information during transmission.</li>
          <li><strong>Access Control:</strong> Restricting access to your personal information to authorized personnel only.</li>
          <li><strong>Regular Audits:</strong> Conducting regular security audits to identify and mitigate potential vulnerabilities.</li>
        </ul>

        <h4>Cookies and Tracking Technologies</h4>
        <p>We may use cookies and similar tracking technologies to track activity on our website and store certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

        <h4>Third-Party Services</h4>
        <p>We may employ third-party companies and individuals to facilitate our service ("Service Providers"), provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. These third parties may have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

        <h4>Your Rights</h4>
        <p>You have the right to:</p>
        <ul>
          <li>Request a copy of the personal information we hold about you.</li>
          <li>Request correction of any inaccuracies in your personal information.</li>
          <li>Request deletion of your personal information, subject to certain exceptions.</li>
        </ul>

        <h4>Changes to This Policy</h4>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website with a revised "Last Updated" date. You are advised to review this Privacy Policy periodically for any changes.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a className="text-dark" href='mailto:care@niyaliving.in'>care@niyaliving.in</a>.</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
