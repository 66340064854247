import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const Faq = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Set the first accordion as active by default

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Helmet>
                <title>FAQs | Niya Living - Get Your Questions Answered</title>
                <meta name="title" content="FAQs | Niya Living - Get Your Questions Answered" />
                <meta name="description" content="Visit Niya Living's FAQs page for answers to common questions about our products, services, and policies. Find helpful information to guide your shopping experience." />
            </Helmet>

            <section
                className="about-page-title"
                style={{ backgroundImage: `url('/images/about-us.png')` }}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="title">FAQ'S</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faqs-section-home1 my-5 pb-md-20">
                <div className="auto-container">
                    <div className="row">
                        <div className="faq-column col-lg-12">
                            <div className="inner-column">
                                <ul className="accordion-box wow fadeInLeft">
                                    {[
                                        { question: "What warranties do I have for installation?", answer: "There are many variations of passages of available, but the majority have suffered alteration in some form." },
                                        { question: "What is included in your services?", answer: "There are many variations of passages of available, but the majority have suffered alteration in some form." },
                                        { question: "What are the payment methods?", answer: "There are many variations of passages of available, but the majority have suffered alteration in some form." },
                                        { question: "How fast will I get my order?", answer: "There are many variations of passages of available, but the majority have suffered alteration in some form." },
                                    ].map((faq, index) => (
                                        <li
                                            key={index}
                                            className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                                        >
                                            <div
                                                className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {faq.question}
                                                <div className="arrow far fa-arrow-right"></div>
                                            </div>
                                            <div className={`acc-content ${activeIndex === index ? 'current' : ''}`}>
                                                <div className="content">
                                                    <div className="text">{faq.answer}</div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;